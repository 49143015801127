import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;   
    position: relative;
    z-index: 25; 
    text-align: center;
`