import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled, {  } from 'styled-components';
import { LoadingComponent } from './components/LoadingComponent';
import {
  SnackbarProvider,
} from 'baseui/snackbar';
import { DarkTheme, BaseProvider } from 'baseui';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';

const HomePage = lazy(() =>
  import('./pages/HomePage')
    .then(({ HomePage }) => ({ default: HomePage })),
);

const ServicesPage = lazy(() =>
  import('./pages/ServicesPage')
    .then(({ ServicesPage }) => ({ default: ServicesPage })),
);

const DiscographyPage = lazy(() =>
  import('./pages/DiscographyPage')
    .then(({ DiscographyPage }) => ({ default: DiscographyPage })),
);

const DownloadsPage = lazy(() =>
  import('./pages/DownloadsPage')
    .then(({ DownloadsPage }) => ({ default: DownloadsPage })),
);

const ShowsPage = lazy(() =>
  import('./pages/ShowsPage')
    .then(({ ShowsPage }) => ({ default: ShowsPage })),
);

const ContactPage = lazy(() =>
  import('./pages/ContactPage')
    .then(({ ContactPage }) => ({ default: ContactPage })),
);

function App() {

  const engine = new Styletron();

  const Wrapper = styled.div`
  color: ${DarkTheme.colors.primaryA};
  background: ${DarkTheme.colors.backgroundAlwaysDark};
  `

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={DarkTheme}>
        <Wrapper>
          <Suspense fallback={<LoadingComponent />}>
            <SnackbarProvider>
              <Routes>

                {/* <Route path='/*' element={<HomePage />}></Route> */}

                <Route path='/' element={<HomePage />}></Route>

                <Route path='/discography' element={<DiscographyPage />}></Route>

                <Route path='/downloads' element={<DownloadsPage />}></Route>

                <Route path='/shows' element={<ShowsPage />}></Route>

                <Route path='/services' element={<ServicesPage />}></Route>

                <Route path='/contact' element={<ContactPage />}></Route>

              </Routes>
            </SnackbarProvider>
          </Suspense>
        </Wrapper>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
