import { 
    Container,
} from './styles';
import { Spinner } from "baseui/spinner";

export const LoadingComponent = () => {  
    return (
        <Container>
            <Spinner />
        </Container>
    )
}